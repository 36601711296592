import React, { lazy } from 'react'
import { Redirect } from 'react-router-dom'
import HomeLayout from 'src/layouts/HomeLayout'
import HomeLayoutAfterLogin from 'src/layouts/HomeLayoutAfterLogin'
export const routes = [
  {
    exact: true,
    path: '/',
    layout: HomeLayout,
    component: lazy(() => import('src/views/pages/Home')),
  },
  {
    exact: true,
    path: '/login',
    component: lazy(() => import('src/views/pages/Auth/Login')),
  },
  {
    exact: true,
    path: '/forgot-password',
    component: lazy(() => import('src/views/pages/Auth/ForgotPassword')),
  },
  {
    exact: true,
    path: '/AboutUs',
    layout: HomeLayout,
    component: lazy(() => import('src/views/pages/Home/AboutUs')),
  },
  {
    exact: true,
    path: '/ContactUs',
    layout: HomeLayout,
    component: lazy(() => import('src/views/pages/Home/ContactUs')),
  },
  {
    exact: true,
    path: '/works',
    layout: HomeLayout,
    component: lazy(() => import('src/views/pages/Home/Works')),
  },

  {
    exact: true,
    path: '/Privacy',
    layout: HomeLayout,
    component: lazy(() => import('src/views/pages/Home/Privacy')),
  },
  {
    exact: true,
    path: '/Condition',
    layout: HomeLayout,
    component: lazy(() => import('src/views/pages/Home/Condition')),
  },
  {
    exact: true,
    path: '/blog',
    layout: HomeLayout,
    component: lazy(() => import('src/views/pages/Blog/Blog')),
  },
  {
    exact: true,
    path: '/blog-details',
    layout: HomeLayout,
    component: lazy(() => import('src/views/pages/Blog/BlogDetail')),
  },
  {
    exact: true,
    path: '/register',
    component: lazy(() => import('src/views/pages/Auth/Register')),
  },
  {
    exact: true,
    path: '/list-property',
    layout: HomeLayout,
    component: lazy(() => import('src/views/pages/ListProperty/Index')),
  },
  {
    exact: true,
    path: '/property-detail',
    layout: HomeLayoutAfterLogin,
    component: lazy(() => import('src/views/pages/PropertyDetail/Index')),
  },
  {
    exact: true,
    path: '/my-profile',
    layout: HomeLayoutAfterLogin,
    component: lazy(() => import('src/views/pages/MyProfile/Index')),
  },

  {
    exact: true,
    path: '/edit-profile',
    layout: HomeLayout,
    component: lazy(() => import('src/views/pages/EditProfile/Index')),
  },

  {
    exact: true,
    path: '/port-folio',
    layout: HomeLayoutAfterLogin,
    component: lazy(() => import('src/views/pages/PortFolio/Index')),
  },
  {
    exact: true,
    path: '/portfolio-detail',
    layout: HomeLayout,
    component: lazy(() => import('src/views/pages/PortfolioDetail/Index')),
  },

  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFound')),
  },

  {
    component: () => <Redirect to="/404" />,
  },
]
