import _ from "lodash";
import { colors, createMuiTheme, responsiveFontSizes } from "@material-ui/core";
import typography from "./typography";

const baseOptions = {
  typography,
  overrides: {
 
    MuiPaper: {
      rounded: {
        borderRadius: "10px !important",
      },
    },
    MuiSwitch: {

      root: {
        width: "58px",
        height: "46px",
        display: "inline-flex",
        padding: "12px",
        zIndex: "0",
        overflow: "hidden",
        position: "relative",
        boxSizing: "border-box",
        flexShrink: "0",
        verticalAlign: "middle",
        background: "#EDE4E4",
        borderRadius: "15px",

      },

    },
    MuiPaper: {
      elevation1: {
        backgroundColor: "#FFFFFF",
        boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",

      },
    },
    MuiFilledInput: {
      root: {
        position: "relative",
        border: "1px solid #EDF1F5",
        borderRradius: "5px",

      },

    },
    MuiInputBase: {
      input:
      {
        font: "inherit",
        color: "#595c62c2",
        width: "100%",
        border: "0",
        height: "1.1876em",
        margin: "0",
        display: "block",
        padding: "6px 0 7px",
        minWidth: "0",
        background: "none",
        boxSizing: "content-box",
        fontSize: "14px",
      },

    },
    MuiTableCell: {
      root: {
        display: "table-cell",
        padding: "16px",
        fontSize: "0.875rem",
        textAlign: "left",
        fontFamily: "'Sora', sans-serif",
        fontWeight: "400",
        lineHeight: "33px",
        padding: "10px 16px",
        textAlign: "center",
        borderBottom: "none",
        verticalAlign: "inherit",
      },
      head: {
        color: "#6E737E",
        fontWeight: "600",
        lineHeight: "20px",
        padding: "16px",
        borderBottom: "2px solid rgba(244, 246, 248, 0.65)",
        fontSize: "16px",
        fontFamily: "'Sora', sans-serif",
        textTransform: "uppercase",

      },
      body: {
        color: "#6E737E",
        fontWeight: "normal",
        lineHeight: "18px",
        fontSize: "14px",
        fontFamily: "'Sora', sans-serif",
      },

    },
    MuiOutlinedInput: {
      input: {

        padding: "19.5px 14px",
        fontSize: "16px",
        border: "none",
      },

      root: {
        position: "relative",
        marginTop: "10px",
        background: " #ECECEC",
        border: "2px solid #DDD9D9",
        boxSizing: "border-box",
        borderRadius: "10px",
      },

    },
    MuiRadio:{
      colorSecondary:{
        '&.Mui-checked':{
          color:"#a2906b",
        }
      },
    },
    MuiButton: {
      containedSizeLarge: {
        padding: "10px 22px",
      },
      containedSecondary: {
        background: "linear-gradient(119.65deg, #C2B18E -3.07%, #9D8A65 106.2%), #9D8A65",
        color: "#fff",
        fontWeight: "400",
        boxShadow: "0px 4px 18px rgba(157, 138, 101, 0.25)",
        borderRadius: "10px",
        fontSize: "18px !important",
        // height: "50px",
        "&:hover": {
          background: "linear-gradient(119.65deg, #fff -3.07%, #fff 106.2%), #fff",
          color: "#9D8A65",

        },
      },
      containedPrimary: {
        color: "#9D8A65",
        border: "2px solid #a6936f",
        backgroundColor: "#ffffff",
        boxShadow: "0px 4px 18px rgba(157, 138, 101, 0.25)",
        borderRadius: "10px",
        fontSize: "18px !important",
        // height: "50px",
        "&:hover": {
          background: "linear-gradient(119.65deg, #C2B18E -3.07%, #9D8A65 106.2%), #9D8A65",
          color: "#fff",
          backgroundColor: "#ffffff",
        },
      },
    },
  },
};
const themesOptions = {
  typography: {
    fontWeight: 400,
    fontFamily: " 'Sora', sans-serif",
  },
  palette: {
    type: "light",
    action: {
      primary: "#20509e",
    },
    background: {
      default: "#FBFBFD",
      dark: "#f3f7f9",
      paper: colors.common.white,
    },
    primary: {
      main: "#898989",
      dark: "#de0d0d",
      light: "#de0d0d",
    },
    secondary: {
      main: "#fff",
    },
    warning: {
      main: "#ffae33",
      dark: "#ffae33",
      light: "#fff1dc",
    },
    success: {
      main: "#54e18c",
      dark: "#54e18c",
      light: "#e2faec",
    },
    error: {
      main: "#ff7d68",
      dark: "#ff7d68",
      light: "#ffe9e6",
    },
    text: {
      primary: "#52565c",
      secondary: "#999999",
    },
    common: {
      black: "#222222",
    },
  },
};

export const createTheme = (config = {}) => {
  let theme = createMuiTheme(_.merge({}, baseOptions, themesOptions));

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
