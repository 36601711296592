import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  Drawer,
  Grid,
  MenuItem,
  Box,
  Container,
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import Logo from './../../component/Logo'
const headersData = [
  {
    label: 'About',
    href: '/AboutUs',
  },
  {
    label: '  How it works',
    href: '/works',
  },
  {
    label: 'Blog',
    href: '/blog',
  },
  {
    label: '  Contact Us',
    href: '/ContactUs',
  },
  {
    label: 'Login',
    href: '/login',
  },
  {
    label: 'Sign Up',
    href: '/register',
  },
]
const useStyles = makeStyles((theme) => ({
  menuButton: {
    fontSize: '15px',
    lineHeight: '24px',
    fontWeight: '500',
    borderRadius: 0,
    minWidth: 'auto',
    color: '#6E737E',
    height: '31px',
    padding: '0px 7px',
    letterSpacing: '1px',
    margin: '0 7px',
    '@media (max-width: 900px)': {
      fontStyle: 'normal',
      letterSpacing: '-0.6px',
      lineHeight: '24px',
      color: '#FFF',
      padding: '15px !important',
      height: '51px',
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    },
    '&.active': {
      color: '#000',
    },
    '&:hover': {
      color: '#000',
    },
  },
  menuButton1: {
    width: '100%',
  },
  login: {
    backgroundColor: '#ec0066',
    marginLeft: '10px',
  },
  loginButton: {
    height: '28px',

    width: '28px',
  },
  toolbar: {
    display: 'flex',
    padding: '10px 0',
    justifyContent: 'space-between',
    height: '100%',
    '@media (max-width: 900px)': {
      paddingLeft: '75px',
      paddingRight: '20px',
      height: '100%',
    },
  },
  maindrawer: {
    height: '100%',
    background: '#0c0731',
    width: '260px',
  },
  logoDrawer: {
    paddingLeft: '10px',
    width: '140px',
    marginBottom: '30px',
  },
  drawerContainer: {
    padding: '20px 0px ',
    height: '100%',
    background: '#fff',
    width: '260px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  drawericon: {
    color: '#000',
    position: 'absolute',
    top: '6px',
    right: '-10px',
    fontSize: '25px',
  },
  logoImg: {
    width: '75px',
    margin: ' 14px 15px 11px 0px',
    objectFit: 'contain',
    '@media (max-width: 500px)': {
      margin: ' 11px 1px 3px 0px',
      width: '52px',
    },
  },
  menuMobile: {
    fontSize: '16px',
    fontWeight: '400',
    fontStyle: 'normal',
    letterSpacing: '-0.6px',
    lineHeight: '1.75',
    color: '#6E737E',
    textTransform: 'uppercase',
    width: '100%',
    padding: '10px',
  },

  containerHeight: {
    height: '100%',
  },
  mainHeader: {
    justifyContent: 'space-between',
    padding: '0px',
  },
}))

export default function Header() {
  const {
    menuMobile,
    menuButton,
    menuButton1,
    toolbar,
    drawerContainer,
    drawericon,
    logoDrawer,
    containerHeight,
    mainHeader,
  } = useStyles()
  const history = useHistory()
  console.log(history.location)

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  })

  const { mobileView, drawerOpen } = state

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1220
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }))
    }

    setResponsiveness()

    window.addEventListener('resize', () => setResponsiveness())
  }, [])

  const displayDesktop = () => {
    return (
      <Container maxWidth="lg" className="p-0">
        <Toolbar className={toolbar}>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
            style={{ paddingLeft: '0px' }}
          >
            <Grid item xs={2}>
              {productLogo}
            </Grid>
            <Grid item xs={10} align="right">
              {getMenuButtons()} {connectBtn}
            </Grid>
          </Grid>
        </Toolbar>
      </Container>
    )
  }

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }))
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }))

    return (
      <Toolbar className={mainHeader}>
        <Drawer
          {...{
            anchor: 'right',
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className={drawerContainer}>
            <img className={logoDrawer} src="images/logo.png" alt="" />

            {getDrawerChoices()}
            <div style={{ padding: '16px' }}>{connectBtn}</div>
          </div>
        </Drawer>

        <div>{productLogo}</div>

        <Grid container>
          <Grid item xs={10}></Grid>
          <Grid item xs={2}>
            <IconButton
              className={drawericon}
              {...{
                edge: 'start',
                color: 'inherit',
                'aria-label': 'menu',
                'aria-haspopup': 'true',
                onClick: handleDrawerOpen,
              }}
            >
              <MenuIcon
                width="60px"
                height="60px"
                style={{ color: '#a69575', fontSize: '30px' }}
              />
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar>
    )
  }

  const getDrawerChoices = () => {
    return headersData.map(({ label, href }) => {
      return (
        <>
          <Button
            {...{
              key: label,
              color: 'inherit',
              to: href,
              component: Link,
              className: menuButton1,
            }}
          >
            <MenuItem className={menuMobile}>{label}</MenuItem>
          </Button>
        </>
      )
    })
  }

  const productLogo = (
    <Box>
      <Link to="/">
        <Logo className="logoImg" />
      </Link>
    </Box>
  )

  const connectBtn = (
    <Button
      variant="contained"
      size="large"
      color="secondary"
      style={{ margin: '0' }}
      component={Link}
      to="/list-property"
    >
      List Property
    </Button>
  )

  const getMenuButtons = () => {
    return headersData.map(({ label, href }) => {
      return (
        <>
          <Button
            {...{
              key: label,
              color: 'inherit',
              to: href,
              component: Link,
              className: menuButton,
            }}
          >
            {label}
          </Button>
        </>
      )
    })
  }

  return (
    <>
      <AppBar
        // position={history.location.pathname !== "/" ? "relative" : "absolute"}
        className={history.location.pathname !== '/' ? 'InnerHeader' : 'Header'}
        elevation={0}
        style={{ backgroundColor: '#E5E5E5',  }}
      >
        <Container
          maxWidth={history.location.pathname !== '/' ? 'fixed' : 'fixed'}
          className={containerHeight}
        >
          {mobileView ? displayMobile() : displayDesktop()}
        </Container>
      </AppBar>
    </>
  )
}
