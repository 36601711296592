import React from 'react'
import {
  Grid,
  Box,
  Container,
  Typography,
  makeStyles,
  Button,
  List,
  ListItem,
  Link,
  Divider,
} from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import FacebookIcon from '@material-ui/icons/Facebook'
import TwitterIcon from '@material-ui/icons/Twitter'
import InstagramIcon from '@material-ui/icons/Instagram'
import TelegramIcon from '@material-ui/icons/Telegram'
import {} from 'react-feather'
const useStyles = makeStyles((theme) => ({
  footerSection: {
    backgroundColor: '#B5A380',
    position: 'relative',
    padding: '50px 0px',

    backgroundPosition: ' bottom left',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(4),
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(4),
    },
    // "&"
    '& h5': {
      fontWeight: 'bold',
      fontSize: '16px',
      letterSpacing: '2px',
      textTransform: 'uppercase',
      color: '#2f086a',
    },
    '& ul': {
      paddingLeft: '0',
    },
    '& p': {
      marginBottom: '0px',
      marginTop: '10px',
      fontWeight: '500',
      fontSize: '12px',
      lineHeight: '18px',
      color: '#000000',
    },
  },
  footerBg: {
    position: 'absolute',
    bottom: '0',
    width: '100%',
    left: '0',
  },
  ListItem: {
    paddingLeft: '0px',
  },
  borderBottmo: {
    overflow: 'hidden',
    background: '#000',
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
  },

  inputBox: {
    position: 'relative',
  },
  footerBgImg: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    zIndex: '1',
  },

  title: {
    fontSize: '20px',
    fontWeight: '600',
    color: 'white',
  },
  subtitle: {
    color: '#fff',
    fontSize: '15px',
    fontWeight: '500',
  },
  socialid: {
    color: ' #fff',
  },
}))

export default function Liquidity() {
  const classes = useStyles()
  return (
    <>
      <Box className={classes.footerSection}>
        <Box
          style={{ margin: '20px 10px 0', position: 'relative', zIndex: '2' }}
        >
          <Container maxWidth="lg">
            <Grid
              container
              justify="space-around"
              alignItems="center"
              spacing={1}
            >
              <Grid item lg={3} md={3} sm={6} xs={12}>
                <img alt="" src="images/footerLogo.png" />
              </Grid>

              <Grid item lg={3} md={3} sm={6} xs={12}>
                <List>
                  <ListItem>
                    <Link href="/aboutus" className={classes.subtitle}>
                      About
                    </Link>
                  </ListItem>

                  <ListItem>
                    <Link href="/works" className={classes.subtitle}>
                      How it works
                    </Link>
                  </ListItem>
                </List>
              </Grid>

              <Grid item lg={3} md={3} sm={6} xs={12}>
                <List>
                  <ListItem>
                    <Link href="/blog" className={classes.subtitle}>
                      Blog
                    </Link>
                  </ListItem>

                  <ListItem>
                    <Link href="/contactus" className={classes.subtitle}>
                      Contact Us
                    </Link>
                  </ListItem>
                </List>
              </Grid>
              <Grid item lg={3} md={3} sm={6} xs={12}>
                <List>
                  <ListItem>
                    <Link href="/privacy" className={classes.subtitle}>
                      Privacy Policy
                    </Link>
                  </ListItem>

                  <ListItem>
                    <Link href="/Condition" className={classes.subtitle}>
                      Term of Use
                    </Link>
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </>
  )
}
