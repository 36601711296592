import React from 'react'
import { makeStyles } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import Footer from './Footer'
import TopBar from './TopBar'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#000',
  },
  MainLayout: {
    backgroundColor: '#E5E5E5',
    minHeight: 'calc(100vh - 417px)',
    paddingTop: '97px',
  },
}))

const MainLayout = ({ children, onButtonClick }) => {
  const classes = useStyles()
  const history = useHistory()
  return (
    <div className={classes.root}>
      <TopBar />

      <div
        style={
          history.location.pathname !== '/'
            ? { display: 'block' }
            : { display: 'none' }
        }
      ></div>

      <div className={classes.MainLayout}>{children}</div>
      <Footer />
    </div>
  )
}

export default MainLayout
